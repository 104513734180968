export default {
	methods: {
		scrollToError() {
			// Scroll to the first available form error
			this.$nextTick(() => {	
				let error = this.$el.querySelector('.invalid-error')
				if (error) error.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
					inline: 'start'
				})
			})
		}
	}
}