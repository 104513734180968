<template>
  <div id="app">
		<vue-announcer data-va="announcer"/>
		<transition
			name="fade"
			mode="out-in"
		>
			<router-view/>
		</transition>
  </div>
</template>

<script>
export default {
	methods: {
		checkSuperAdmin() {
			const checkStorage = localStorage.getItem('super-admin')
			this.$store.commit('authSuperAdmin', checkStorage)
		}
	},
	created() {
		this.checkSuperAdmin();
	}
}
</script>