export default {
	methods: {
		trackPageView() {
			if (process.env.VUE_APP_ENABLE_MIXPANEL != 'false' && process.env.NODE_ENV === 'production') {
				this.$mixpanel.track('Page View');
			}
		}
	},
	created() {
		this.trackPageView()
	}
}