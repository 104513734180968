<template>
	<section class="container-fluid page-header text-white">
		<div class="container py-5">
			<img class="brook-logo" src="@/assets/images/brook-logo.png" alt="Brook" title="Brook" />
			<div class="fade-page-content">
				<h1 class="display-3 my-3 text-uppercase font-weight-bold">{{ title }}</h1>
				<p class="p-0">
					<slot name="content"></slot>
				</p>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		title: {
			default: 'Page Title',
			type: String,
		}
	}
}
</script>
