// Initial Empty State
const defaultState = () => ({
	hasHadSex: '',
	datesOfSex: [],
	firstPeriod: '',
	firstPeriodOther: '',
	normalCycle: '',
	normalCycleOther: '',
	timeOfEarliestSex: '',
	ec: '',
	pregnancyRisk: ''
})

// State
const state = defaultState()

// Getters
const getters = {
	getHasDatesOfSex: state => state.hasHadSex,
	getDatesOfSex: state => state.datesOfSex,
	getFirstPeriod: state => state.firstPeriod,
	getFirstPeriodOther: state => state.firstPeriodOther,
	getNormalCycle: state => state.normalCycle,
	getNormalCycleOther: state => state.normalCycleOther,
	getTimeOfEarliestSex: state => state.timeOfEarliestSex,
	getEC: state => state.ec,
	getPregnancyRisk: state => state.pregnancyRisk
}

// Mutations
const mutations = {
	updateHasHadSex(state, value) {
		state.hasHadSex = value 
	},
	updateDatesOfSex(state, value) {
		state.datesOfSex = value 
	},
	updateFirstPeriod(state, value) {
		state.firstPeriod = value 
	},
	updateFirstPeriodOther(state, value) {
		state.firstPeriodOther = value 
	},
	updateNormalCycle(state, value) {
		state.normalCycle = value 
	},
	updateNormalCycleOther(state, value) {
		state.normalCycleOther = value 
	},
	updateTimeOfEarliestSex(state, value) {
		state.timeOfEarliestSex = value 
	},
	updateEC(state, value) {
		state.ec = value
	},
	updatePregnancyRisk(state, value) {
		state.pregnancyRisk = value
	},
	reset(state) {
		Object.assign(state, defaultState())
	}
}

// Actions
const actions = {
	clearDatesOfSex(context) {
		context.commit('updateDatesOfSex', '')
	},
	clearFirstPeriod(context) {
		context.commit('updateFirstPeriod', '')
	},
	clearFirstPeriodOther(context) {
		context.commit('updateFirstPeriodOther', '')
	},
	clearNormalCycle(context) {
		context.commit('updateNormalCycle', '')
	},
	clearNormalCycleOther(context) {
		context.commit('updateNormalCycleOther', '')
	},
	resetECC({ commit }) {
		commit('reset')
	}
}

export default {
	state,
	getters,
	mutations,
	actions
}