<template>
	<fieldset v-if="!hidden" class="form-group w-100">
		<legend v-if="title" class="font-weight-bold">{{ title }} <span v-if="required" class="req text-danger">*</span></legend>
		<p v-if="subtitle" class="small">{{ subtitle }}</p>
		<div class="form-row">
			<!-- Content Slot -->
			<slot></slot>
		</div>
	</fieldset>
</template>

<script>
export default {
	props: {
		title: {
			default: '',
			type: String
		},
		subtitle: {
			default: '',
			type: String
		},
		hidden: {
			default: false,
			type: Boolean
		},
		required: {
			default: false,
			type: Boolean
		}
	},
}
</script>