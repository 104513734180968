// Axios
import axios from 'axios'

// Variables
const brook = {
	url: process.env.VUE_APP_BROOK_ENDPOINT,
}

// Initial Empty State
const defaultState = () => ({
	user: {
		token: localStorage.getItem('user-token') === null ? '' : localStorage.getItem('user-token'),
		refreshToken: '',
		status: '',
		clinic: localStorage.getItem('user-clinic') === null ? '' : localStorage.getItem('user-clinic'),
		super: false
	},
	adminError: '',
	entry: '',
	showModal: false
})

// State
const state = defaultState()

// Getters
const getters = {
	isAuthenticated: state => !!state.user.token,
	authStatus: state => state.user.status,
	getAdminError: state => state.adminError,
	getEntry: state => state.entry,
	getShowModal: state => state.showModal,
	getUserToken: state => state.user.token,
	getUserClinic: state => state.user.clinic,
	isSuperAdmin: state => state.user.super
}

// Mutations
const mutations = {
	// Admin
	setEntry(state, value) {
		state.entry = value
	},
	setShowModal(state, value) {
		state.showModal = value
	},
	setAdminError(state, value) {
		state.adminError = value
	},
	// Auth
	authLogin( state ) {
		state.user.status = 'loading'
	},
	authLogout( state ) {
		state.user.token = ''
	},
	authSuccess ( state, token ) {
		state.user.status = 'success',
		state.user.token = token
	},
	authError ( state ) {
		state.user.status = 'error'
	},
	authClinic( state, value ) {
		state.user.clinic = value
	},
	authSuperAdmin( state, value ) {
		state.user.super = value
	},
}

// Actions
const actions = {
	// General Admin
	showModal({ commit }, value) {
		commit('setShowModal', value)
	},
	setEntry({ commit }, value) {
		commit('setEntry', value)
	},
	setAdminError({ commit }, value) {
		commit('setAdminError', value)
	},
	// Auth Calls
	async authLogin({ commit }, user) {
		const result = await axios.get(
			`${brook.url}/v1/user/authenticate`, {
				params: user
			}
		)

		// Set user status
		await commit('authLogin')

		if ( result.status === 200 ) {

			// Store tokens in local storage.
			const token = result.data.data.access_token
			await localStorage.setItem('user-token', token)

			const clinic = result.data.data.clinic
			await localStorage.setItem('user-clinic', clinic)
			await commit('authClinic', clinic)

			const refresh = result.data.data.refresh_token
			await localStorage.setItem('refresh-token', refresh)

			const superAdmin = result.data.data.super_admin
			await localStorage.setItem('super-admin', superAdmin)
			await commit('authSuperAdmin', superAdmin)

			// Set default header
			axios.defaults.headers.common['Authorization'] = token

			// Login in user.
			await commit('authSuccess', token)

		} else {
			// Auth Error
			commit('authError', result.meta.error)

			// Remove user local storage on error
			localStorage.removeItem('user-token')
			localStorage.removeItem('user-clinic')
			localStorage.removeItem('super-admin')
			localStorage.removeItem('refresh-token')
		}
	},
	async authLogout({ commit }) {
		return new Promise(( resolve ) => {
			// Reset user token state
			commit('authLogout')
			commit('authClinic', '')
			commit('authSuperAdmin', false)

			// Remove user token from local storage
			localStorage.removeItem('user-token')
			localStorage.removeItem('user-clinic')
			localStorage.removeItem('super-admin')
			localStorage.removeItem('refresh-token')

			// Remove default headers
			delete axios.defaults.headers.common['Authorization']

			resolve()
		})
	}
}

export default {
	state,
	getters,
	mutations,
	actions
}