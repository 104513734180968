import Vue from 'vue'
import Vuex from 'vuex'

// Admin
import admin from './modules/admin/'

// Form Steps
import form from './modules/form/'

// ECC
import ecc from './modules/ecc/'

Vue.use(Vuex)

// Initial Empty State
function defaultState () {
	return {
		loading: false,
		hasReadPageTerms: false,
		stepsCompleted: {
			stepA: false,
			stepB: false,
			stepC: false,
			stepD: false,
			stepE: false,
			ecc: null,
		}
	}
}

export default new Vuex.Store({	
	// Base State
  state: defaultState(),
	// Base Getters
	getters: {
		loading: state => state.loading,
		hasReadPageTerms: state => state.hasReadPageTerms,
		getStepA: state => state.stepsCompleted.stepA,
		getStepB: state => state.stepsCompleted.stepB,
		getStepC: state => state.stepsCompleted.stepC,
		getStepD: state => state.stepsCompleted.stepD,
		getStepE: state => state.stepsCompleted.stepE,
		getEcc: state => state.stepsCompleted.ecc,
	},
	// Base Mutations
  mutations: {
		loading(state, value) {
			state.loading = value
		},
		setHasReadPageTerms(state, value) {
			state.hasReadPageTerms = value
		},
		completeStepA(state, value) {
			state.stepsCompleted.stepA = value
		},
		completeStepB(state, value) {
			state.stepsCompleted.stepB = value
		},
		completeStepC(state, value) {
			state.stepsCompleted.stepC = value
		},
		completeStepD(state, value) {
			state.stepsCompleted.stepD = value
		},
		completeStepE(state, value) {
			state.stepsCompleted.stepE = value
		},
		completeEcc(state, value) {
			state.stepsCompleted.ecc = value
		},
		reset(state) {
			Object.assign(state, defaultState())
		}
	},
	// Base Actions
  actions: {
		updateHasReadPageTerms({ commit }, value) {
			commit('setHasReadPageTerms', value)
		},
		resetState({ commit }) {
			commit('reset')
		}
	},
	// Base Modules
  modules: {
		admin,
		form,
		ecc
  }
})
