<template>
	<div class="w-100">
		<!-- Reasons selection -->
		<Checkboxes
			required
			title="What brings you to Brook?"
			subtitle="Please select all that apply"
			:values="reasons"
			name="reasonForVisit"
			getter="getReasonForVisit"
			updateMethod="updateReasonForVisit"
		/>
		
		<!-- Reasons: Other -->
		<form-row v-if="selectedOther">
			<form-input
				required
				label="Other Reason"
				id="other-issue"
				name="otherReasonForVisit"
				dataStore="form"
				updateMethod="updateOtherReasonForVisit"
			/>
		</form-row>
	</div>
</template>

<script>
// State
import { mapGetters } from "vuex"

// Form Components
import formInput from '@/components/form/form-input'
import formRow from '@/components/form/form-row'
import Checkboxes from '@/components/form/checkbox/group'

export default {
	components: {
		formInput,
		formRow,
		Checkboxes
	},
	data() {
		return {
			reasons: [
				{
					name: "I have had unprotected sex/sexual contact",
					id: 'unprotected-sex',
					image: 'unprotected-sex'
				}, {
					name: "I want contraception",
					id: 'contraception',
					image: 'contraception'
				}, {
					name: "I might be pregnant",
					id: 'pregnant',
					image: 'pregnant'
				}, {
					name: "I want to talk to someone",
					id: 'talk-to-someone',
					image: 'talk'
				}, {
					name: 'Other',
					id: 'other-issues'
				}
			],
			selectedOther: null,
		}
	},
	computed: {
		...mapGetters([
			'getReasonForVisit',
			'getOtherReasonForVisit'
		])
	},
	watch: {
		getReasonForVisit(value) {
			if ( value.includes('Other') ) {
				this.selectedOther = true
				this.$announcer.polite('The form has been updated.')
			} else {
				this.selectedOther = false

				// Clear other reason if switching between radio buttons.
				if ( this.getOtherReasonForVisit.length ) {
					this.$store.dispatch('clearOtherReasonForVisit')
					this.$announcer.polite('The form has been updated.')
				}
			}
		}
	},
	methods: {
		checkState() {
			// Check stored state
			if (this.getReasonForVisit.length && this.getReasonForVisit.includes('Other')) {
				this.selectedOther = true
			} 
		},
	},
	mounted() {
		this.checkState()
	}
}
</script>