import Vue from 'vue'
import VueRouter from 'vue-router'

// Import store for route guards
import store from '../store'

// Import globals for route link
import * as GLOBALS from "../inc/globals"

// Initial Route
import Home from '../views/Home.vue'

// Steps
const AboutYou         = () => import('../views/form/AboutYou.vue')
const MoreAboutYou     = () => import('../views/form/MoreAboutYou.vue')
const YourLife         = () => import('../views/form/YourLife.vue')
const YourMentalHealth = () => import('../views/form/YourMentalHealth.vue')
const PregnancyRisk    = () => import('../views/form/PregnancyRisk.vue')
const ThankYou         = () => import('../views/form/ThankYou.vue')

// Admin
const Login      = () => import('../views/admin/Login.vue')
const Dashboard  = () => import('../views/admin/Dashboard.vue')
const User       = () => import('../views/admin/User.vue')
const Admins      = () => import('../views/admin/Admins.vue')
const UpdateAdmin = () => import('../views/admin/UpdateAdmin.vue')
const CreateAdmin = () => import('../views/admin/CreateAdmin.vue')

// 404
const NothingFound = () => import('../views/404.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: GLOBALS.default.PATH_WELCOME,
    name: 'Welcome',
		component: Home,
		meta: {
			title: 'Let\'s get started'
		}
  },
  {
    path: GLOBALS.default.PATH_ABOUT_YOU,
    name: 'AboutYou',
		component: AboutYou,
		meta: {
			title: 'About You'
		},
		// Check is Step A has been completed
		beforeEnter: (to, from, next) => {
			if ( !store.getters.getStepA ) next(GLOBALS.default.PATH_WELCOME)
			else next()
		}
	},
	{
    path: GLOBALS.default.PATH_MORE_ABOUT_YOU,
    name: 'MoreAboutYou',
		component: MoreAboutYou,
		meta: {
			title: 'More about you - Relationships and Sex'
		},
		// Check is Step B has been completed
		beforeEnter: (to, from, next) => {
			if ( !store.getters.getStepB ) next(GLOBALS.default.PATH_ABOUT_YOU)
			else next()
		}
	},
	{
    path: GLOBALS.default.PATH_YOUR_LIFE,
    name: 'YourLife',
		component: YourLife,
		meta: {
			title: 'Your Life'
		},
		// Check is Step C has been completed
		beforeEnter: (to, from, next) => {
			if ( !store.getters.getStepC ) next(GLOBALS.default.PATH_MORE_ABOUT_YOU)
			else next()
		}
	},
	{
    path: GLOBALS.default.PATH_YOUR_MENTAL_HEALTH,
    name: 'YourMentalHealth',
		component: YourMentalHealth,
		meta: {
			title: 'Your Mental Health'
		},
		// Check is Step D has been completed
		beforeEnter: (to, from, next) => {
			if ( !store.getters.getStepD ) next(GLOBALS.default.PATH_YOUR_LIFE)
			else next()
		}
	},
	{
		path: GLOBALS.default.PATH_PREGNANCY_RISK,
		name: 'PregnancyRisk',
		component: PregnancyRisk,
		meta: {
			title: 'Pregnancy Risk'
		},
		// Check is Step E has been completed
		beforeEnter: (to, from, next) => {
			if ( !store.getters.getStepE ) next(GLOBALS.default.PATH_YOUR_MENTAL_HEALTH)
			else next()
		}
	},
	{
    path: GLOBALS.default.PATH_THANK_YOU,
    name: 'ThankYou',
		component: ThankYou,
		meta: {
			title: 'Thank You'
		},
		// Check is Step E has been completed
		beforeEnter: (to, from, next) => {
			if ( !store.getters.getStepE ) next(GLOBALS.default.PATH_YOUR_MENTAL_HEALTH)
			else next()
		}
	},
	{
    path: GLOBALS.default.PATH_LOGIN,
    name: 'Login',
		component: Login,
		meta: {
			title: 'Login'
		},
		// Check is user is already logged in and authenticated
		beforeEnter: (to, from, next) => {
			if ( store.getters.isAuthenticated ) next(GLOBALS.default.PATH_DASHBOARD)
			else next()
		}
	},
	{
    path: GLOBALS.default.PATH_DASHBOARD,
    name: 'Dashboard',
		component: Dashboard,
		meta: {
			title: 'Dashboard'
		},
		// Check is user is authenticated
		beforeEnter: (to, from, next) => {
			if ( !store.getters.isAuthenticated ) next(GLOBALS.default.PATH_LOGIN)
			else next()
		}
  },
	{
    path: `${GLOBALS.default.PATH_USER}/:id`,
    name: 'User',
		component: User,
		// Check is user is authenticated
		beforeEnter: (to, from, next) => {
			if ( !store.getters.isAuthenticated ) next(GLOBALS.default.PATH_LOGIN)
			else next()
		}
	},
	{
    path: GLOBALS.default.PATH_ADMINS,
    name: 'Admins',
		component: Admins,
		meta: {
			title: 'Admins'
		},
		// Check is user is authenticated and has super admin access
		beforeEnter: (to, from, next) => {
			if ( store.getters.isSuperAdmin === 'true' || store.getters.isSuperAdmin === true ) next()
			else next(GLOBALS.default.PATH_DASHBOARD)
		}
	},
	{
    path: `${GLOBALS.default.PATH_ADMINS}/:id`,
    name: 'UpdateAdmin',
		component: UpdateAdmin,
		// Check is user is authenticated and has super admin access
		beforeEnter: (to, from, next) => {
			if ( store.getters.isSuperAdmin === 'true' || store.getters.isSuperAdmin === true ) next()
			else next(GLOBALS.default.PATH_DASHBOARD)
		}
	},
	{
    path: GLOBALS.default.PATH_ADMIN_CREATE,
    name: 'CreateAdmin',
		component: CreateAdmin,
		meta: {
			title: 'Create Admin'
		},
		// Check is user is authenticated and has super admin access
		beforeEnter: (to, from, next) => {
			if ( store.getters.isSuperAdmin === 'true' || store.getters.isSuperAdmin === true ) next()
			else next(GLOBALS.default.PATH_DASHBOARD)
		}
  },
	{
		path: '*',
		name: 'NothingFound',
		component: NothingFound,
		meta: {
			title: 'Nothing Found'
		},
	},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
	routes,
	// Scroll to top on route change
	scrollBehavior() {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve({
					x: 0,
					y: 0,
				})
			}, 300)
		})
	}
})

/**
 * Router Meta Data
 * Updates title from Router.
 */
router.beforeEach((to, from, next) => {
	// Check if this is a dynamic user
	if ( to.params.id ) {
		document.title = `${to.params.id} | Brook`
	} else {
		document.title = `${to.meta.title} | Brook`
	}
	next()
})

export default router
