import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Styles
import './assets/sass/styles.scss'

// Axios
import axios from "axios"
Vue.prototype.$axios = axios

// Globals setup
import * as GLOBALS from "./inc/globals"
Vue.prototype.$GLOBALS = GLOBALS.default

// Vue Announcer
import VueAnnouncer from "@vue-a11y/announcer"
Vue.use(VueAnnouncer, {}, router)

// Hotjar for production (Set ID in ENV)
import Hotjar from 'vue-hotjar'
if (process.env.VUE_APP_ENABLE_HOTJAR != 'false') {
	Vue.use (Hotjar, {
		id: process.env.VUE_APP_HOTJAR_ID,
		isProduction: process.env.NODE_ENV === 'production'
	})
}

// Mixpanel for production (Set ID in ENV)
import VueMixpanel from 'vue-mixpanel'
if (process.env.VUE_APP_ENABLE_MIXPANEL != 'false' && process.env.NODE_ENV === 'production') {
	Vue.use(VueMixpanel, {
		token: process.env.VUE_APP_MIXPANEL_ID
	})
}

// Defaults
Vue.config.productionTip = false

// Api
import api from '@/inc/api'
Vue.prototype.$api = api

// Api defaults
api.defaults.timeout = 10000

// Get request for the access token
api.interceptors.request.use(
	config => {
		const token = localStorage.getItem('user-token')

		if ( token ) {
			config.headers.common['Authorization'] = token
		}

		return config
	}
)

// Response interceptor used for admin access only
api.interceptors.response.use(
	response => {
		if ( response.status === 200 ) {
			return Promise.resolve(response)
		} else {
			return Promise.reject(response)
		}
	},
	error => {
		// Store original request config
		let originalRequest = error.config

		// Set error
		if ( error.response ) store.dispatch('setAdminError', error.response.data.meta.error.error_message)

		/**
		 * User authentication error
		 * Checks status code and the error type
		 */
		if (
			error.response &&
			error.response.data.meta.status_code === 400 &&
			(error.response.data.meta.error.error_type === 'user_auth_error' || error.response.data.meta.error.error_type === 'access_token_expired') &&
			!originalRequest._retry
		) {
			// Set retry to true
			originalRequest._retry = true
			
			// Call for refresh token
			const refreshToken = window.localStorage.getItem('refresh-token')
			return api.get(
				'/v1/user/authenticate', {
					params: {
						refresh_token: refreshToken
					}
				}
			)
			.then(result => {
				// Update user access token
				const token = result.data.data.access_token
				localStorage.setItem('user-token', token)

				// Set Headers
				axios.defaults.headers.common['Authorization'] = token
				originalRequest.headers['Authorization'] = token

				// Remove any error
				store.dispatch('setAdminError', '')

				// Return original request
				return axios(originalRequest)
			}).catch(err => {
				// Remove all console errors
				if (err) console.clear()
				// Logout and redirect back to the login page
				store.dispatch( 'authLogout' ).then(() => {
					router.push({
						path: '/login'
					})
				})
			})
		}
	}
)

/**
 * Authentication headers
 * Check access token intitially for all calls.
 */
const token = window.localStorage.getItem('user-token')
if (token) axios.defaults.headers.common['Authorization'] = token

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
