<template>
	<div
		class="form-group col col-md-6"
		:class="block ? 'mr-50' : null"
	>
		<label
			v-if="label"
			:for="name"
			:class="sronly ? 'screen-reader-text' : null"
		>{{ label }} <span v-if="required" class="text-danger">*</span></label>

		<div class="input-group">
			<select
				class="custom-select col-md-3"
				:class="getErrors[`${name}`] ? 'border-danger' : null"
				:id="name"
				:name="name"
				v-model="hour"
			>
				<option selected disabled value="">HH...</option>
				<option
					v-for="hour in hours"
					:key="hour"
					:value="hour"
				>{{ hour }}</option>
			</select>

			<select
				class="custom-select col-md-3"
				:class="getErrors[`${name}`] ? 'border-danger' : null"
				:id="`${name}-minute`"
				:name="name"
				v-model="minute"
			>
				<option selected disabled value="">MM...</option>
				<option
					v-for="minute in minutes"
					:key="minute"
					:value="minute"
				>{{ minute }}</option>
			</select>
		</div>

		<div v-if="timeOption" class="custom-control mt-2 custom-radio col-md-6">
			<input
				class="custom-control-input"
				type="radio"
				name="i-dont-know-time"
				id="i-dont-know-time"
				value="I don't know"
				v-model="timeNone"
			/>
			<label
				for="i-dont-know-time"
				class="custom-control-label custom-control-label__small custom-control-label__single"
			>
				<span class="w-100">I don't know</span>
			</label>
		</div>
		
		<small vif="description" class="form-text text-muted">{{ description }}</small>

		<Alert
			error
			type="danger"
			v-show="getErrors[`${name}`]"
			:class="getErrors[`${name}`] ? 'invalid-error' : null"
		>
			<small>{{ getErrors[`${name}`] }}</small>
		</Alert>
	</div>
</template>

<script>
// State
import { mapGetters } from 'vuex'

// General
import Alert from '@/components/general/alert'

export default {
	components: {
		Alert
	},
	data() {
		return {
			hours: [
				"00",
				"01",
				"02",
				"03",
				"04",
				"05",
				"06",
				"07",
				"08",
				"09",
				"10",
				"11",
				"12",
				"13",
				"14",
				"15",
				"16",
				"17",
				"18",
				"19",
				"20",
				"21",
				"22",
				"23"
			],
			minutes: [
				"00",
				"05",
				"10",
				"15",
				"20",
				"25",
				"30",
				"35",
				"40",
				"45",
				"50",
				"55"
			],
			timeNone: '',
			hour: '',
			minute: ''
		}
	},
	props: {
		label: {
			default: '',
			type: String
		},
		option: {
			default: '',
			type: String
		},
		name: {
			default: '',
			type: String
		},
		ariaDescribedBy: {
			default: false,
			type: [String, Boolean]
		},
		description: {
			default: '',
			type: String
		},
		block: {
			default: false,
			type: Boolean
		},
		dataStore: {
			default: '',
			type: String
		},
		updateMethod: {
			default: '',
			type: String
		},
		updateTimeOption: {
			default: '',
			type: String
		},
		nameTimeOption: {
			default: '',
			type: String
		},
		sronly: {
			default: false,
			type: Boolean
		},
		required: {
			type: Boolean
		},
		timeOption: {
			default: false,
			type: Boolean
		}
	},
	watch: {
		time(value) {
			// Combine hour & minute for time data
			const regex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/
			if ( value && regex.test(value) )
				this.$store.commit(this.updateMethod, value)
				delete this.getErrors[`${this.name}`]
		},
		hour(value) {
			if ( value.length && this.timeNone.length ) {
				this.timeNone = ''
				this.$store.commit(this.updateTimeOption, "")
			}
		},
		minute(value) {
			if ( value.length && this.timeNone.length ) {
				this.timeNone = ''
				this.$store.commit(this.updateTimeOption, "")
			}
		},
		timeNone(value) {
			// If no time option is selected remove hour and minute data
			if ( value.length && this.hour.length || value.length && this.minute.length ) {
				this.$store.commit(this.updateMethod, '')
				this.hour = ''
				this.minute = ''
			}

			if ( value === "I don't know" ) {
				this.$store.commit(this.updateTimeOption, "I don't know")
			}
		}
	},
	computed: {
		...mapGetters([
			'getErrors'
		]),
		time() {
			return `${this.hour}:${this.minute}`
		},
	},
	methods: {
		setTime() {
			// Get stored time
			const time = this.$store.state[this.dataStore][this.name]
			const timeOther = this.$store.state[this.dataStore][this.nameTimeOption]

			if ( timeOther && timeOther.length) {
				this.timeNone = "I don't know"
				return
			}

			const splitTime = time.split(':')
			const condition = time === '' || time === undefined
			// Set hour and minute
			this.hour = condition ? '' : splitTime[0]
			this.minute = condition ? '' : splitTime[1] 
		},
	},
	mounted() {
		this.setTime()
	}
}
</script>