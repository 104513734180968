<template>
	<div
		class="form-group col col-md-6"
		:class="block ? 'mr-50' : null"
	>
		<label
			v-if="label"
			:for="name"
			:class="sronly ? 'screen-reader-text' : null"
		>{{ label }} <span v-if="required" class="text-danger">*</span></label>
		<div class="input-group">
			<input
				class="form-control"
				:class="getErrors[`${name}`] ? 'border-danger' : null"
				:pattern="type === 'number' ? '[0-9]*' : null"
				:inputmode="type === 'number' ? 'numeric' : null"
				:type="password ? passwordType : type"
				:id="name"
				:name="name"
				:placeholder="placeholder"
				v-model.lazy="input"
				autocomplete="off"
			/>
			<div v-if="prepend" class="input-group-prepend">
				<div class="input-group-text">{{ prepend }}</div>
			</div>
			<div v-if="password" class="input-group-prepend">
				<button
					@click="showPassword"
					type="button"
					class="input-group-text cursor-pointer"
				>
					<eye-icon v-if="passwordHide" size="1x" />
					<eye-off-icon v-else size="1x" />
					<span class="sr-only">View</span>
				</button>
			</div>
		</div>
		<small v-if="description" class="form-text text-muted">{{ description }}</small>

		<!-- Additional content throughout app -->
		<slot></slot>

		<Alert
			error
			type="danger"
			v-show="getErrors[`${name}`]"
			:class="getErrors[`${name}`] ? 'invalid-error' : null"
		>
			<small>{{ getErrors[`${name}`] }}</small>
		</Alert>
	</div>
</template>

<script>
// State
import { mapGetters } from 'vuex'

// Icons
import { EyeIcon, EyeOffIcon } from 'vue-feather-icons'

// General Components
import Alert from '@/components/general/alert'

export default {
	components: {
		Alert,
		EyeIcon,
		EyeOffIcon
	},
	model: {
    prop: "value",
    event: "input"
	},
	data() {
		return {
			error: '',
			passwordHide: true,
			passwordType: 'password'
		}
	},
	props: {
		label: {
			default: '',
			type: String
		},
		name: {
			default: '',
			type: String
		},
		type: {
			default: 'text',
			type: String
		},
		prepend: {
			default: '',
			type: [String, Number]
		},
		password: {
			default: false,
			type: Boolean
		},
		value: {
			default: '',
			type: String
		},
		placeholder: {
			default: false,
			type: [String, Boolean]
		},
		ariaDescribedBy: {
			default: false,
			type: [String, Boolean]
		},
		description: {
			default: '',
			type: String
		},
		block: {
			default: false,
			type: Boolean
		},
		min: {
			default: 0,
			type: [Number, String]
		},
		max: {
			default: 100,
			type: [Number, String]
		},
		dataStore: {
			default: '',
			type: String
		},
		updateMethod: {
			default: '',
			type: String
		},
		sronly: {
			default: false,
			type: Boolean
		},
		required: {
			type: Boolean
		},
		postcode: {
			default: false,
			type: Boolean
		}
	},
	computed: {
		...mapGetters([
			'getErrors'
		]),
		input: {
			get() {
				if ( this.postcode ) {
					return this.dataStore ?
						this.$store.state[this.dataStore][this.name].replace(/ /g, '') :
						this.value.replace(/ /g, '')
				} else {
					return this.dataStore ?
						this.$store.state[this.dataStore][this.name] :
						this.value
				}
			},
			set(value) {
				// Remove set error
				if ( value ) delete this.getErrors[`${this.name}`]

				// Set value
				if ( this.postcode ) {
					this.updateMethod ?
						this.$store.commit(this.updateMethod, value.replace(/ /g, '')) :
						this.$emit('input', value.replace(/ /g, ''))
				} else {
					this.updateMethod ?
						this.$store.commit(this.updateMethod, value) :
						this.$emit('input', value)
				}
			}
		}
	},
	methods: {
		showPassword() {
			if ( this.passwordHide ) {
				this.passwordType = 'text'
				this.passwordHide = false
			} else {
				this.passwordType = 'password'
				this.passwordHide = true
			}
		}
	}
}
</script>