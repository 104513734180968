<template>
	<div
		class="form-group col col-md-6"
		:class="block ? 'mr-50' : null"
	>
		<label
			v-if="label"
			:for="name"
			:class="sronly ? 'screen-reader-text' : null"
		>{{ label }} <span v-if="required" class="text-danger">*</span></label>

		<div v-if="option" class="input-group-prepend">
			<label class="input-group-text" :for="name">{{ option }}</label>
		</div>

		<select
			class="custom-select"
			:class="getErrors[`${name}`] ? 'border-danger' : null"
			:id="name"
			:name="name"
			v-model="selected"
		>
			<option selected disabled value="">Choose...</option>

			<slot></slot>
		</select>

		<small vif="description" class="form-text text-muted">{{ description }}</small>

		<Alert
			error
			type="danger"
			v-show="getErrors[`${name}`]"
			:class="getErrors[`${name}`] ? 'invalid-error' : null"
		>
			<small>{{ getErrors[`${name}`] }}</small>
		</Alert>
	</div>
</template>

<script>
// State
import { mapGetters } from 'vuex'

// General
import Alert from '@/components/general/alert'

export default {
	components: {
		Alert
	},
	props: {
		label: {
			default: '',
			type: String
		},
		option: {
			default: '',
			type: String
		},
		name: {
			default: '',
			type: String
		},
		ariaDescribedBy: {
			default: false,
			type: [String, Boolean]
		},
		description: {
			default: '',
			type: String
		},
		block: {
			default: false,
			type: Boolean
		},
		dataStore: {
			default: '',
			type: String
		},
		updateMethod: {
			default: '',
			type: String
		},
		sronly: {
			default: false,
			type: Boolean
		},
		required: {
			type: Boolean
		}
	},
	computed: {
		...mapGetters([
			'getErrors'
		]),
		selected: {
			get() {
				return this.dataStore ? 
					this.$store.state[this.dataStore][this.name] :
					this.value
			},
			set(value) {
				// Remove set error
				if ( value ) delete this.getErrors[`${this.name}`]
				// Set value
				this.updateMethod ?
					this.$store.commit(this.updateMethod, value) :
					this.$emit('input', value)
			}
		}
	}
}
</script>