<template>
	<!-- Terms Confirmation -->
	<div class="card">
		<div class="card-header">
			Terms &amp; Conditions
		</div>
		<div class="card-body">
			<form @submit.prevent="agreeTerms">
				<p class="card-text">Brook promises to keep your information safe and protect it from being lost, damaged or shared with anyone it shouldn’t be.</p>

				<p class="card-text">The information we will ask you will form part of your clinical record with Brook, and should reduce the time it will take to have your appointment. The information will be stored in a secure database that only authorised members of staff will have access to. The authorised staff will then enter it into our clinical record system and it will then deleted from the secure database. It will not be stored in the secure database longer than 30 days.</p>

				<p class="card-text">For full information about how Brook use your data, please read our full privacy notice on our website <a class="text-primary text-underline" rel="noopener" href="https://www.brook.org.uk/privacy-and-confidentiality/" target="_blank">www.brook.org.uk/privacy-and-confidentiality/</a></p>

				<p class="card-text">If you have any questions about the use of your data you can:</p>

				<ul class="card-text">
					<li>speak to a member of staff to ask for a paper copy</li>
					<li>contact Brook’s Data Protection Officer at <a class="text-primary text-underline" href="mailto:dataprotection@brook.org.uk">dataprotection@brook.org.uk</a></li>
					<li>write to us at Data Protection Officer, 81 London Road, Liverpool, L3 8JA</li>
				</ul>

				<p class="card-text">By completing and submitting this form you agree to Brook using your data in this way.</p>

				<div class="form-check border-top pt-4 mt-4">
					<input
						class="form-check-input"
						type="checkbox"
						value=""
						id="terms"
						v-model="hasReadTerms"
					>
					<label class="form-check-label" for="terms">
						I agree to the terms and conditions
					</label>
				</div>

				<alert error v-show="termsError" type="danger" class="m-0 mt-3">
					<small>You must accept the terms and conditions before being able to proceed</small>
				</alert>

				<button
					:disabled="!hasReadTerms"
					type="submit"	
					class="btn btn-primary mt-4"
				>
					Continue
				</button>
			</form>

		</div>
	</div>
</template>

<script>
import alert from '@/components/general/alert'

export default {
	components: {
		alert
	},
	data() {
		return {
			hasReadTerms: false,
			termsError: false
		}
	},
	methods: {
		agreeTerms() {
			if ( this.hasReadTerms ) {
				// Remove error if there was one
				this.termsError = true
				// Continue to Questions
				this.$store.dispatch('updateHasReadPageTerms', true)
				// Scroll Back to the top if needed
				window.scrollTo(0, 0)
			} else {
				// Show error
				this.termsError = true
			}
		}
	}
}
</script>