// Initial Empty State
const defaultState = () => ({
	// Step A
	clinic: '',
	postcode: '',
	ageIsValid: false,
	appointmentDate: '',
	appointmentTime: '',
	appointmentTimeOther: '',
	reasonForVisit: [],
	otherReasonForVisit: '',
	// Step B
	firstName: '',
	lastName: '',
	preferredName: '',
	dateOfBirth: '',
	fullPostcode: '',
	contactNumber: '',
	ethnicityGroup: '',
	ethnicity: '',
	otherEthnicity: '',
	sexuality: '',
	otherSexuality: '',
	sexAssignedAtBirth: '',
	preferredPronoun: '',
	otherPreferredPronoun: '',
	// Step C
	partners: [],
	partnersAge: '',
	genitals: '',
	hasHadSex: '',
	hasHadUpsi: '',
	contraceptiveMethods: [],
	suspectedContractionFailure: '',
	// Step D
	employment: [],
	missedEducation: '',
	daysMissed: '',
	living: '',
	otherLiving: '',
	support: [],
	otherSupport: '',
	intoxicantsUsed: [],
	internetTechCurrentlyUsed: [],
	// Step E
	feeling: '',
	experiences: [],
	// General
	pageContent: [],
	// Errors
	errors: {}
})

// State
const state = defaultState()

// Getters
const getters = {
	// Step A
	getClinic: state => state.clinic,
	ageIsValid: state => state.ageIsValid,
	getAppointmentDate: state => state.appointmentDate,
	getAppointmentTime: state => state.appointmentTime,
	getAppointmentTimeOther: state => state.appointmentTimeOther,
	getReasonForVisit: state => state.reasonForVisit,
	getOtherReasonForVisit: state => state.otherReasonForVisit,
	// Step B
	getFirstName: state => state.firstName,
	getLastName: state => state.lastName,
	getPreferredName: state => state.preferredName,
	getDateOfBirth: state => state.dateOfBirth,
	getFullPostcode: state => state.fullPostcode,
	getContactNumber: state => state.contactNumber,
	getEthnicityGroup: state => state.ethnicityGroup,
	getEthnicity: state => state.ethnicity,
	getOtherEthnicity: state => state.otherEthnicity,
	getSexuality: state => state.sexuality,
	getOtherSexuality: state => state.otherSexuality,
	getSexAssignedAtBirth: state => state.sexAssignedAtBirth,
	getPreferredPronoun: state => state.preferredPronoun,
	getOtherPreferredPronoun: state => state.otherPreferredPronoun,
	// Step C
	getPartners: state => state.partners,
	getPartnersAge: state => state.partnersAge,
	getGenitals: state => state.genitals,
	getHasHadSex: state => state.hasHadSex,
	getHasHadUpsi: state => state.hasHadUpsi,
	getContraceptiveMethods: state => state.contraceptiveMethods,
	getSuspectedContractionFailure: state => state.suspectedContractionFailure,
	// Step D
	getEmployment: state => state.employment,
	getMissedEducation: state => state.missedEducation,
	getDaysMissed: state => state.daysMissed,
	getLiving: state => state.living,
	getOtherLiving: state => state.otherLiving,
	getSupport: state => state.support,
	getOtherSupport: state => state.otherSupport,
	getIntoxicantsUsed: state => state.intoxicantsUsed,
	getInternetTechCurrentlyUsed: state => state.internetTechCurrentlyUsed,
	// Step E
	getFeeling: state => state.feeling,
	getExperiences: state => state.experiences,
	// General
	getPageContent: state => state.pageContent,
	// Errors
	getErrors: state => state.errors
}

// Mutations
const mutations = {
	// Step A
	updateClinic(state, value) {
		state.clinic = value
	},
	updatePostcode(state, value) {
		state.postcode = value
	},
	updateIsValidAge(state, value) {
		state.ageIsValid = value
	},
	updateAppointmentDate(state, value) {
		state.appointmentDate = value
	},
	updateAppointmentTime(state, value) {
		state.appointmentTime = value
	},
	updateAppointmentTimeOther(state, value) {
		state.appointmentTimeOther = value
	},
	updateReasonForVisit(state, value) {
		state.reasonForVisit = value
	},
	updateOtherReasonForVisit(state, value) {
		state.otherReasonForVisit = value
	},
	// Step B
	updateFirstName(state, value) {
		state.firstName = value
	},
	updateLastName(state, value) {
		state.lastName = value
	},
	updatePreferredName(state, value) {
		state.preferredName = value
	},
	updateDateOfBirth(state, value) {
		state.dateOfBirth = value
	},
	updateFullPostcode(state, value) {
		state.fullPostcode = value
	},
	updateContactNumber(state, value) {
		state.contactNumber = value
	},
	updateEthnicityGroup(state, value) {
		state.ethnicityGroup = value
	},
	updateEthnicity(state, value) {
		state.ethnicity = value
	},
	updateOtherEthnicity(state, value) {
		state.otherEthnicity = value
	},
	updateSexuality(state, value) {
		state.sexuality = value
	},
	updateOtherSexuality(state, value) {
		state.otherSexuality = value
	},
	updateSexAssignedAtBirth(state, value) {
		state.sexAssignedAtBirth = value
	},
	updatePreferredPronoun(state, value) {
		state.preferredPronoun = value
	},
	updateOtherPreferredPronoun(state, value) {
		state.otherPreferredPronoun = value
	},
	// Step C
	updatePartners(state, value) {
		state.partners = value
	},
	removePartners(state, value) {
		state.partners.splice(
			state.partners.indexOf(value), 1
		)
	},
	updatePartnersAge(state, value) {
		state.partnersAge = value
	},
	updateGenitals(state, value) {
		state.genitals = value
	},
	updateHasHadSex(state, value) {
		state.hasHadSex = value
	},
	updateHasHadUpsi(state, value) {
		state.hasHadUpsi = value
	},
	updateContraceptiveMethods(state, value) {
		state.contraceptiveMethods = value
	},
	removeContraceptiveMethods(state, value) {
		state.contraceptiveMethods.splice(
			state.contraceptiveMethods.indexOf(value), 1
		)
	},
	updateSuspectedContractionFailure(state, value) {
		state.suspectedContractionFailure = value
	},
	// Step D
	updateEmployment(state, value) {
		state.employment = value
	},
	removeEmployment(state, value) {
		state.employment.splice(
			state.employment.indexOf(value), 1
		)
	},
	updateMissedEducation(state, value) {
		state.missedEducation = value
	},
	updateDaysMissed(state, value) {
		state.daysMissed = value
	},
	updateLiving(state, value) {
		state.living = value
	},
	updateOtherLiving(state, value) {
		state.otherLiving = value
	},
	updateSupport(state, value) {
		state.support = value
	},
	removeSupport(state, value) {
		state.support.splice(
			state.support.indexOf(value), 1
		)
	},
	updateOtherSupport(state, value) {
		state.otherSupport = value
	},
	updateIntoxicantsUsed(state, value) {
		state.intoxicantsUsed = value
	},
	removeIntoxicantsUsed(state, value) {
		state.intoxicantsUsed.splice(
			state.intoxicantsUsed.indexOf(value), 1
		)
	},
	updateInternetTechCurrentlyUsed(state, value) {
		state.internetTechCurrentlyUsed = value
	},
	removeInternetTechCurrentlyUsed(state, value) {
		state.internetTechCurrentlyUsed.splice(
			state.internetTechCurrentlyUsed.indexOf(value), 1
		)
	},
	// Step E
	updateFeeling(state, value) {
		state.feeling = value
	},
	updateExperiences(state, value) {
		state.experiences = value
	},
	// General
	updatePageContent(state, value) {
		state.pageContent = value
	},
	reset(state) {
		Object.assign(state, defaultState())
	},
	// Errors
	updateErrors(state, object) {
		state.errors = object
	}
}

// Actions
const actions = {
	// Step A
	clearOtherReasonForVisit(context) {
		context.commit('updateOtherReasonForVisit', '')
	},
	// Step B
	clearEthnicity(context) {
		context.commit('updateEthnicity', '')
	},
	clearOtherEthnicity(context) {
		context.commit('updateOtherEthnicity', '')
	},
	clearOtherSexuality(context) {
		context.commit('updateOtherSexuality', '')
	},
	clearOtherPreferredPronoun(context) {
		context.commit('updateOtherPreferredPronoun', '')
	},
	// Step C
	clearPartners(context, value) {
		context.commit('updatePartners', value)
	},
	clearPartnersOptions(context, value) {
		context.commit('removePartners', value)
	},
	clearContraceptiveMethods(context, value) {
		context.commit('updateContraceptiveMethods', value)
	},
	clearContraceptiveMethodsOptions(context, value) {
		context.commit('removeContraceptiveMethods', value)
	},
	clearContraceptiveFailure(context, value) {
		context.commit('updateSuspectedContractionFailure', value)
	},
	// Step D
	clearEmployment(context, value) {
		context.commit('updateEmployment', value)
	},
	clearEmploymentOptions(context, value) {
		context.commit('removeEmployment', value)
	},
	clearMissedEducation(context) {
		context.commit('updateMissedEducation', '')
		context.commit('updateDaysMissed', '')
	},
	clearOtherLiving(context) {
		context.commit('updateOtherLiving', '')
	},
	clearSupport(context, value) {
		context.commit('updateSupport', value)
	},
	clearSupportOptions(context, value) {
		context.commit('removeSupport', value)
	},
	clearOtherSupport(context) {
		context.commit('updateOtherSupport', '')
	},
	clearInternetTechCurrentlyUsed(context, value) {
		context.commit('updateInternetTechCurrentlyUsed', value)
	},
	clearInternetTechCurrentlyUsedOptions(context, value) {
		context.commit('removeInternetTechCurrentlyUsed', value)
	},
	clearIntoxicantsUsed(context, value) {
		context.commit('updateIntoxicantsUsed', value)
	},
	clearIntoxicantsUsedOptions(context, value) {
		context.commit('removeIntoxicantsUsed', value)
	},
	resetForm({ commit }) {
		commit('reset')
	},
	clearErrors(context) {
		context.commit('updateErrors', {})
	}
}

export default {
	state,
	getters,
	mutations,
	actions
}