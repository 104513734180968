<template>
	<div class="btn-group mt-2 d-block mx-auto" role="group" aria-label="Form actions">
		<button
			class="btn py-3 btn-primary font-weight-bold border-rounded mt-2 w-100"
			type="submit"
			:disabled="disabled"
			@click="onSubmit"
		>
			<div v-show="$store.state.loading" class="spinner-border spinner-border-sm text-light" role="status">
				<span class="sr-only">Loading...</span>
			</div>
			<span :class="$store.state.loading ? 'btn__loading' : null">{{ title }}</span>
			<arrow-right-icon size="1x" class="ml-2" />
		</button>
		<button
			v-if="removeBackButton"
			class="btn py-3 border-rounded font-weight-bold btn-light mt-4 w-100"
			type="button"
			@click="onBack"
		>
			<arrow-left-icon size="1x" class="mr-2 mt-n1" />
			<span>{{ backTitle }}</span>
		</button>
	</div>
</template>

<script>
// Icons
import { ArrowRightIcon, ArrowLeftIcon } from 'vue-feather-icons'

export default {
	components: {
		ArrowRightIcon,
		ArrowLeftIcon
	},
	props: {
		disabled: {
			default: false,
			type: Boolean
		},
		title: {
			default: 'Continue',
			type: String
		},
		backTitle: {
			default: 'Back',
			type: String
		},
		removeBackButton: {
			default: true,
			type: Boolean
		}
	},
	data() {
		return {
			loading: false
		}
	},
	methods: {
		onSubmit() {
			this.$store.commit('loading', true)
		},
		onBack() {
			this.$router.go(-1)
		}
	}
}
</script>