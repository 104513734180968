// Environment
const API_URL = process.env.VUE_APP_BROOK_ENDPOINT

// Steps
const PATH_WELCOME            = '/'
const PATH_ABOUT_YOU          = '/about-you'
const PATH_MORE_ABOUT_YOU     = '/more-about-you'
const PATH_YOUR_LIFE          = '/your-life'
const PATH_YOUR_MENTAL_HEALTH = '/your-mental-health'
const PATH_PREGNANCY_RISK     = '/pregnancy-risk'
const PATH_THANK_YOU          = '/thank-you'

// Auth
const PATH_LOGIN = '/login'

// Admin
const PATH_DASHBOARD    = '/dashboard'
const PATH_USER         = '/user'
const PATH_ADMINS       = '/admins'
const PATH_ADMIN_UPDATE = '/update-admin'
const PATH_ADMIN_CREATE = '/create-admin'

// Validations
const REGEX_PHONE = /^(\+44\s?7\d{3}|\(?07\d{3}\)?)\s?\d{3}\s?\d{3}$/g
const REGEX_POSTCODE = /^[a-z]{1,2}\d[a-z\d]?\s*\d[a-z]{2}$/i

export default {
	API_URL,
	PATH_WELCOME,
	PATH_ABOUT_YOU,
	PATH_MORE_ABOUT_YOU,
	PATH_YOUR_LIFE,
	PATH_YOUR_MENTAL_HEALTH,
	PATH_PREGNANCY_RISK,
	PATH_THANK_YOU,
	PATH_LOGIN,
	PATH_DASHBOARD,
	PATH_USER,
	PATH_ADMINS,
	PATH_ADMIN_UPDATE,
	PATH_ADMIN_CREATE,
	REGEX_PHONE,
	REGEX_POSTCODE
}