<template>
	<div
		class="form-group col col-md-6"
		:class="block ? 'mr-50' : null"
	>
		<label
			v-if="label"
			:for="name"
			:class="sronly ? 'screen-reader-text' : null"
		>{{ label }} <span v-if="required" class="text-danger">*</span></label>
		<flat-pickr
			v-model="date"
			:config="config"
			class="form-control"
			:class="getErrors[`${name}`] ? 'border-danger' : null"
			:placeholder="placeholder"               
			:name="name"
		/>
		<small v-if="description" class="form-text text-muted">{{ description }}</small>
	
		<Alert
			error
			type="danger"
			v-show="getErrors[`${name}`]"
			:class="getErrors[`${name}`] ? 'invalid-error' : null"
		>
			<small>{{ getErrors[`${name}`] }}</small>
		</Alert>
	</div>
</template>

<script>
// State
import { mapGetters } from 'vuex'

// General Components
import Alert from '@/components/general/alert'

// Mulit Date Picker
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
	data () {
		return {
			config: {
				altFormat: "F j, Y",
				altInput: true,
				dateFormat: 'Y-m-d',
				mode: this.mode,
				maxDate: this.maxDate,
				minDate: this.minDate
			},   
		}
	},
	components: {
		flatPickr,
		Alert
	}, 
	props: {
		mode: {
			default: 'single',
			type: String
		},
		maxDate: {
			default: '',
			type: String
		},
		minDate: {
			default: '',
			type: String
		},
		label: {
			default: '',
			type: String
		},
		name: {
			default: '',
			type: String
		},
		placeholder: {
			default: false,
			type: [String, Boolean]
		},
		ariaDescribedBy: {
			default: false,
			type: [String, Boolean]
		},
		description: {
			default: '',
			type: String
		},
		block: {
			default: false,
			type: Boolean
		},
		dataStore: {
			default: '',
			type: String
		},
		updateMethod: {
			default: '',
			type: String
		},
		sronly: {
			default: false,
			type: Boolean
		},
		required: {
			type: Boolean
		}
	},
	computed: {
		...mapGetters([
			'getErrors'
		]),
		date: {
			get() {
				return this.dataStore ?
					this.$store.state[this.dataStore][this.name] :
					this.value
			},
			set(value) {
				// Remove set error
				if ( value ) delete this.getErrors[`${this.name}`]
				// Set value
				this.updateMethod ?
					this.$store.commit(this.updateMethod, value) :
					this.$emit('input', value)
			}
		}
	}   
}
</script>

<style type="css">
	.form-control[readonly] {
		background-color: white !important;
	}

	.flatpickr-day.selected,
	.flatpickr-day.selected:hover {
    background: #652f91;
		border-color: #652f91;
	}

	.flatpickr-months .flatpickr-prev-month:hover svg,
	.flatpickr-months .flatpickr-next-month:hover svg {
    fill: #32bec0;
	}
</style>