<template>
	<div
		class="alert"
		:class="[
			`alert-${type}`,
			error ? 'px-3 py-2 mt-2' : null
		]"
		role="alert"
	>
		<h4 v-if="title" class="alert-heading">{{ title }}</h4>
		<!-- Content Slot -->
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		type: {
			defualt: 'primary',
			type: String
		},
		title: {
			default: '',
			type: String
		},
		error: {
			default: false,
			type: Boolean
		}
	}
}
</script>