<template>
  <div class="home">
		<page-header
			title="Let's get started"
		>
			<template v-slot:content>
				Hi, welcome to Brook! To quickly get you the help and support you need, let's start with some basics, like why you've come to us.
			</template>
		</page-header>
		<page-container>
			<!-- Terms and Conditions -->
			<terms v-if="!hasReadPageTerms" />

			<form
				v-else
				autocomplete="off"
				@submit.prevent="validateForm"
			>
				<!-- Appointment Date & Time -->
				<form-row title="When is your face to face appointment or call?">
					<form-date
						required
						mode="single"
						minDate="today"
						label="Date"
						placeholder="Select date"
						dataStore="form"
						name="appointmentDate"
						updateMethod="updateAppointmentDate"
					/>
					<form-time
						timeOption
						label="Time"
						name="appointmentTime"
						dataStore="form"
						updateMethod="updateAppointmentTime"
						updateTimeOption="updateAppointmentTimeOther"
						nameTimeOption="appointmentTimeOther"
					/>
				</form-row>

				<!-- Select Clinic -->
				<form-row title="Which clinic is your face to face appointment or call with?">
					<form-select
						required
						name="clinic"
						dataStore="form"
						updateMethod="updateClinic"
						label="Which clinic"
					>
						<option
							v-for="clinic in clinics"
							:key="clinic.id"
							:value="clinic.name"
						>{{ clinic.name }}</option>
					</form-select>
				</form-row>

				<!-- Reasons Options -->
				<reasons />
				
				<form-submit
					:removeBackButton="false"
					title="Continue to about you"
				/>
			</form>
		</page-container>
  </div>
</template>

<script>
// State
import { mapGetters } from 'vuex'

// Mixins
import scrollToError from '@/mixins/scroll-to-error'
import mixpanelTracking from '@/mixins/mixpanel'

// Content Clinics JSON
import clinics from '@/inc/content/clinics'

// Form Components
import pageHeader from '@/components/general/page-header'
import pageContainer from '@/components/general/page-container'
import formDate from '@/components/form/form-date'
import formTime from '@/components/form/form-time'
import formSelect from '@/components/form/form-select'
import formRow from '@/components/form/form-row'
import formSubmit from '@/components/form/form-submit'

// Step Components
import terms from '@/components/steps/welcome/terms'
import reasons from '@/components/steps/welcome/reasons'

export default {
	mixins: [scrollToError, mixpanelTracking],
	components: {
		pageHeader,
		pageContainer,
		formDate,
		formTime,
		formSelect,
		formRow,
		formSubmit,
		reasons,
		terms
	},
	data() {
		return {
			clinics: clinics
		}
	},
	computed: {
		...mapGetters([
			'hasReadPageTerms',
			'getErrors',
			'getAppointmentDate',
			'getAppointmentTimeOther',
			'getClinic',
			'getReasonForVisit',
			'getOtherReasonForVisit'
		])
	},
	methods: {
		async validateForm() {
			// Clear loading state
			this.$store.commit('loading', false)

			// Check form validation
			if (
				!!this.getAppointmentDate &&
				!!this.getClinic &&
				!!this.getReasonForVisit.length
			) {
				// Check other conditions
				if (this.getReasonForVisit.includes('Other') && !this.getOtherReasonForVisit.length) {
					// Set errors
					await this.$store.commit('updateErrors', {
					...this.getErrors,
						otherReasonForVisit: this.getReasonForVisit.includes('Other') && !this.getOtherReasonForVisit.length ? 'Please enter the reason you are here today' : ''
					})
					
					// Scroll to error
					await this.scrollToError()
					// Cancel submission
					return
				}

				// Set stepA to complete & clear errors
				await this.$store.commit('completeStepA', true)
				await this.$store.dispatch('clearErrors')

				// Proceed to next step
				await this.$router.push(
					this.$GLOBALS.PATH_ABOUT_YOU
				)
			} else {
				// Set errors
				await this.$store.commit('updateErrors', {
					...this.getErrors,
					appointmentDate: !this.getAppointmentDate.length ? 'Please enter a date' : '',
					clinic: !this.getClinic.length ? 'Please select a clinic' : '',
					reasonForVisit: !this.getReasonForVisit.length ? 'Please select one or more reasons you are here today' : '',
					otherReasonForVisit: this.getReasonForVisit.includes('Other') && !this.getOtherReasonForVisit.length ? 'Please enter the reason you are here today' : ''
				})

				// Scroll to error
				await this.scrollToError()
			}
		}
	}
}
</script>
