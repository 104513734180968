<template>
	<input
		class="custom-control-input"
		type="checkbox"
		:id="id"
		:checked="isChecked"
		@change="change"
	/>
</template>

<script>
export default {
  name: "checkbox",
  model: {
    prop: "checked",
    event: "change"
  },
	props: {
		checked: {
			type: [Boolean, String, Array]
		},
		value: {
			type: [Array, Object, String]
		},
		id: {
			type: String
		},
		update: {
			type: String
		}
	},
  computed: {
    isChecked() {
      return this.checked.includes(this.value)
    }
  },
  methods: {
    change() {
      const checked = this.checked.slice()
      const found = checked.indexOf(this.value)
      if (found !== -1) {
        checked.splice(found, 1)
      } else {
        checked.push(this.value)
			}
			
			this.$emit("change", checked)
			this.$store.commit(this.update, checked)
    }
  }
}
</script>
