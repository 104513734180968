<template>
	<fieldset v-if="!hidden" class="form-group w-100">
		<legend v-if="title" class="font-weight-bold">{{ title }} <span v-if="required" class="req text-danger">*</span></legend>
		<p v-if="subtitle" class="small">{{ subtitle }}</p>

		<div ref="checkbox" class="form-row form-row__checkbox">
			<div
				v-for="(item, index) in values"
				:key="index"
				class="custom-control custom-checkbox col-12 col-md-3"
			>
				<Checkbox
					:key="index"
					v-model="selected"
					:value="item.name"
					:id="item.id"
					:update="updateMethod"
				/>
				<label :for="item.id" class="custom-control-label">
					<div v-if="item.image" class="custom-control-image">
						<inline-svg
							:src="require(`@/assets/images/svg/${item.image}-icon.svg`)"
							width="60"
							height="60"
							:aria-label="item.name"
							:title="item.name"
						/>
					</div>
					<span class="w-100">{{ item.name }}</span>
				</label>
			</div>
		</div>

		<Alert
			error
			type="danger"
			v-show="getErrors[`${name}`]"
			:class="getErrors[`${name}`] ? 'invalid-error' : null"
		>
			<small>{{ getErrors[`${name}`] }}</small>
		</Alert>
	</fieldset>
</template>

<script>
// State
import { mapGetters } from 'vuex'

// Svg & polyfill
import('@/../node_modules/innersvg-polyfill')
import InlineSvg from 'vue-inline-svg'

// Checkbox Components
import Checkbox from './input'
import Alert from '@/components/general/alert'

export default {
	data() {
		return {
			selected: []
		}
	},
	components: {
		Checkbox,
		InlineSvg,
		Alert,
	},
	props: {
		required: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: ''
		},
		subtitle: {
			type: String,
			default: ''
		},
		values: {
			type: [Array, Object, String]
		},
		name: {
			type: String
		},
		updateMethod: {
			type: String
		},
		getter: {
			type: String
		},
		hidden: {
			default: false,
			type: Boolean
		},
	},
	computed: {
		state() {
			const get = this.getter
			const data = this.$store.getters[get]
			return data
		},
		...mapGetters([
			'getErrors'
		]),
	},
	watch: {
		state(value) {
			// Remove set error
			if ( value ) delete this.getErrors[`${this.name}`]
			// Set value
			this.selected = value
		},
	},
	methods: {
		checkState() {
			// Check stored state
			const get = this.getter
			const data = this.$store.getters[get]

			if ( data && data.length ) {
				this.selected = data
			}
		},
	},
	mounted() {
		this.checkState()
	}
}
</script>